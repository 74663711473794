<template>
<div class="card card-custom gutter-b">
    <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-10">
            <div style="background-color: #1BC5BD;" class="card card-custom">
                <div class="card-body text-green">
                    <h3 class="card-label text-green" style="color:white">
                        <i class="fa fa-info-circle" style="color:white;" aria-hidden="true"></i> Paparan
                    </h3>
                    <hr>
                    <p style="color:white; font-size:15px;">Selamat PTSP anda telah menjadi Nomine. Tahap selanjutnya silahkan download undangan dan panduan pada file berikut ini:</p>
                    <div>
                        <a v-if="user.id_role === 'ppb_kl'" :href="undangankl" target="_blank" class="btn btn-primary" style="float: right; background-color: #004d00; border-color: #004d00"><i class="fa fa-download" aria-hidden="true"/> Undangan dan Panduan</a>
                        <a v-else-if="user.id_role === 'ptsp_provinsi'" target="_blank" :href="undanganprov" class="btn btn-primary" style="float: right; background-color: #004d00; border-color: #004d00"><i class="fa fa-download" aria-hidden="true"/> Undangan dan Panduan</a>
                        <a v-else-if="user.id_role === 'ptsp_kabupaten'" target="_blank" :href="undangankab" class="btn btn-primary" style="float: right; background-color: #004d00; border-color: #004d00"><i class="fa fa-download" aria-hidden="true"/> Undangan dan Panduan</a>
                        <a v-else-if="user.id_role === 'ptsp_kota'" target="_blank" :href="undangankot" class="btn btn-primary" style="float: right; background-color: #004d00; border-color: #004d00"><i class="fa fa-download" aria-hidden="true"/> Undangan dan Panduan</a>
                        
                    </div>
                    <br>
                    <p style="color:white; font-size:15px;margin-top: 30px;">Selanjutnya unggah dokumen presentasi dan lampiran</p>
                    <div class="form-group row">
                        <label style="color:white; font-size:15px;" class="col-4">Unggah Presentasi (PPT) (Maksimal 30 MB)</label>
                            <div class="col-3">
                                <b-form-file v-model="pemaparan.file_ppt_pemaparan" accept=".ppt, .pptx" @change="file_presentasi" id="fileppt" type="file" ref="file"></b-form-file>
                            </div>
                            <div class="col-2">
                                <button v-if="paparan === 'Ya' && pemaparan.file_ppt_pemaparan != null" type="button" @click="editpemaparanpresentasi(pemaparan.id)" style="float: right" class="btn btn-primary  text-uppercase"><i class="flaticon-upload"></i> Unggah</button>
                            </div>
                            <div class="col-1" v-if="pemaparan.fileppt != null">
                                <a target="_blank" :href="pemaparan.fileppt" class="btn btn-primary" style="background-color: #004d00; border-color: #004d00"><i class="fa fa-download" aria-hidden="true"/> Unduh</a>
                            </div>
                    </div>
                    <div class="form-group row">
                        <label style="color:white; font-size:15px;" class="col-lg-4 col-form-label">Unggah Lampiran (PDF) (Maksimal 30 MB)</label>
                        <div class="col-lg-3">
                            <b-form-file v-model="pemaparan.file_lampiran_pemaparan" accept=".pdf" @change="file_lampiran" id="filelampiran" type="file" ref="file"></b-form-file>
                        </div>
                        <div class="col-2">
                            <button v-if="paparan === 'Ya' && pemaparan.file_lampiran_pemaparan != null" type="button" @click="editpemaparanlampiran(pemaparan.id)" style="float: right" class="btn btn-primary  text-uppercase"> <i class="flaticon-upload"></i>Unggah</button>
                        </div>
                        <div class="col-1" v-if="pemaparan.filelampiran != null">
                            <a target="_blank" :href="pemaparan.filelampiran" class="btn btn-primary" style="background-color: #004d00; border-color: #004d00"><i class="fa fa-download" aria-hidden="true"/> Unduh</a>
                        </div>
                    </div>
                    <p style="color:white; font-size:15px;">Setelah anda selesai melaksanakan Pemaparan silahkan download berita acara dibawah ini (setelah seluruh timpenilai menyelesaikan penilaian)</p>
                    <div>
                        <button class="btn btn-primary" @click="urlba()" style="float: right; background-color: #004d00; border-color: #004d00"><i class="fa fa-download" aria-hidden="true"/> Unduh BA</button>
                    </div>
                </div>
            </div>
            <!-- <h4 class="mb-10 font-weight-bold text-dark">INFORMASI IDENTITAS PTSP Pemda</h4> -->
        <!--begin::Input-->
        <!-- <div class="form-group row">
            <label class="col-lg-4 col-form-label"></label>
            <div class="col-lg-8">
                <div class="row">
                    <div class="col-xl-12">
                       
                    </div>
                </div>
                <button type="button" @click="editpemaparan" style="background-color: #030A8C;float: right; border-color: #030A8C" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4">Simpan</button>
            </div>
        </div> -->
        </div>
        <!--end::Example-->
    </div>
</div>
</template>
<script>
import Vue from 'vue';
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import Swal from "sweetalert2";
import VueLoading from 'vuejs-loading-plugin'

Vue.use(VueLoading)

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: 'Loading', // default 'Loading'
  loading: true, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: 'rgb(255,255,255)', // set custom background
  classes: ['myclass'] // array, object or string
})

export default {
  components: {vSelect},
  data() {
    return {
    url: localStorage.getItem('baseapi'),
    user: JSON.parse(localStorage.getItem('user')),
    tanggal: localStorage.getItem('tanggal'),
    token: localStorage.getItem('id_token'),
    tahun: new Date().getFullYear(),
    id:'',
    pemaparan:[],
    paparan:'',
    undanganprov: 'https://penilaiankinerja.mysurvey.id/paparan/Provinsi.pdf',
    undangankab: 'https://penilaiankinerja.mysurvey.id/paparan/Kabupaten.pdf',
    undangankot: 'https://penilaiankinerja.mysurvey.id/paparan/Kota.pdf',
    undangankl: 'https://penilaiankinerja.mysurvey.id/paparan/KL.pdf',
    tataterbit: 'https://penilaiankinerja.bkpm.go.id:2087/public/dokumen/template_pemaparan/Tata',
    paparanptsp:'https://penilaiankinerja.bkpm.go.id:2087/public/dokumen/template_pemaparan/Undangan_Pemaparan_Nomine_dan_Uji_Petik_Nomine_Pemerintah_Provinsi-308_A.7_B.3_2023.pdf',
    paparankl:'https://penilaiankinerja.bkpm.go.id:2087/public/dokumen/template_pemaparan/Template Paparan Nomine PPB KL 2022.pdf'
    };
  },

  mounted() {
    // set the tab from previous
    // this.setActivePreviousTab();

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Paparan" }]);
    this.loaddata()

    // this.$nextTick(() => {
    //   const hljs = this.$el.querySelectorAll(".hljs");
    //   hljs.forEach(hl => {
    //     hl.classList.remove("hljs");
    //     hl.classList.add(`language-${hl.classList[1]}`);
    //   });
    // });
  },
  methods: {
    editpemaparanpresentasi(id){
        this.$loading(true);
        let formData = new FormData();
                formData.append('file_ppt_pemaparan', this.pemaparan.file_ppt_pemaparan);
                // tampak_depan
        if(this.pemaparan.file_ppt_pemaparan == null){
            this.$bvToast.toast('File kosong, silahkan pilih file terlebih dahulu', {
                    title: `Failed`,
                    variant: `danger`,
                    solid: true
                })
        }else{
            if (this.user.id_role === 'ptsp_prov' || this.user.id_role === 'ptsp_provinsi') {
          axios
            .post(this.url+`/tabel_nomine_provinsi/${id}`, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'xth': this.token
                }
            })
            .then(response => {
              this.$loading(false);
              this.$bvToast.toast('Berhasil Diunggah', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.loaddata()
            // console.log(response.data);
            console.log(response.data);
            // $('#import form :input').val("");
            //   this.loadData(this.idcabang)
              
              return response
              
            })
            .catch(error=>{
            this.$loading(false);
            console.log(error)
            this.$bvToast.toast('Gagal Diunggah', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
              return error
              })
        } else if(this.user.id_role === 'ptsp_kabupaten'){
            axios
            .post(this.url+`/tabel_nomine_kabupaten/${id}`, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'xth': this.token
                }
            })
            .then(response => {
              this.$loading(false);
              this.$bvToast.toast('Berhasil Diunggah', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.loaddata()
            // console.log(response.data);
            console.log(response.data);
            // $('#import form :input').val("");
            //   this.loadData(this.idcabang)
              
              return response
              
            })
            .catch(error=>{
            this.$loading(false);
            console.log(error)
            this.$bvToast.toast('Gagal Diunggah', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
              return error
              })
        } else if(this.user.id_role === 'ptsp_kot' || this.user.id_role === 'ptsp_kota'){
            axios
            .post(this.url+`/tabel_nomine_kota/${id}`, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'xth': this.token
                }
            })
            .then(response => {
              this.$loading(false);
              this.$bvToast.toast('Berhasil Diunggah', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.loaddata()
            // console.log(response.data);
            console.log(response.data);
            // $('#import form :input').val("");
            //   this.loadData(this.idcabang)
              
              return response
              
            })
            .catch(error=>{
            this.$loading(false);
            console.log(error)
            this.$bvToast.toast('Gagal Diunggah', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
              return error
              })
        } else {
            axios
            .post(this.url+`/tabel_nomine_kl/${id}`, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'xth': this.token
                }
            })
            .then(response => {
              this.$loading(false);
              this.$bvToast.toast('Berhasil Diunggah', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.loaddata()
            // console.log(response.data);
            console.log(response.data);
            // $('#import form :input').val("");
            //   this.loadData(this.idcabang)
              
              return response
              
            })
            .catch(error=>{
            this.$loading(false);
            console.log(error)
            this.$bvToast.toast('Gagal Diunggah', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
              return error
              })
        }
        }
          
        
      },
    editpemaparanlampiran(id){
        this.$loading(true);
          let formData = new FormData();
                formData.append('file_lampiran_pemaparan', this.pemaparan.file_lampiran_pemaparan);
                // tampak_depan
        if (this.user.id_role === 'ptsp_prov' || this.user.id_role === 'ptsp_provinsi') {
          axios
            .post(this.url+`/tabel_nomine_provinsi/${id}`, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'xth': this.token
                }
            })
            .then(response => {
              this.$loading(false);
              this.$bvToast.toast('Berhasil Diunggah', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.loaddata()
            // console.log(response.data);
            console.log(response.data);
            // $('#import form :input').val("");
            //   this.loadData(this.idcabang)
              
              return response
              
            })
            .catch(error=>{
            this.$loading(false);
            console.log(error)
            this.$bvToast.toast('Gagal Diunggah', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
              return error
              })
        } else if(this.user.id_role === 'ptsp_kabupaten'){
            axios
            .post(this.url+`/tabel_nomine_kabupaten/${id}`, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'xth': this.token
                }
            })
            .then(response => {
              this.$loading(false);
              this.$bvToast.toast('Berhasil Diunggah', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.loaddata()
            // console.log(response.data);
            console.log(response.data);
            // $('#import form :input').val("");
            //   this.loadData(this.idcabang)
              
              return response
              
            })
            .catch(error=>{
            this.$loading(false);
            console.log(error)
            this.$bvToast.toast('Gagal Diunggah', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
              return error
              })
        } else if(this.user.id_role === 'ptsp_kot' || this.user.id_role === 'ptsp_kota'){
            axios
            .post(this.url+`/tabel_nomine_kota/${id}`, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'xth': this.token
                }
            })
            .then(response => {
              this.$loading(false);
              this.$bvToast.toast('Berhasil Diunggah', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.loaddata()
            // console.log(response.data);
            console.log(response.data);
            // $('#import form :input').val("");
            //   this.loadData(this.idcabang)
              
              return response
              
            })
            .catch(error=>{
            this.$loading(false);
            console.log(error)
            this.$bvToast.toast('Gagal Diunggah', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
              return error
              })
        } else {
            axios
            .post(this.url+`/tabel_nomine_kl/${id}`, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'xth': this.token
                }
            })
            .then(response => {
              this.$loading(false);
              this.$bvToast.toast('Berhasil Diunggah', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.loaddata()
            // console.log(response.data);
            console.log(response.data);
            // $('#import form :input').val("");
            //   this.loadData(this.idcabang)
              
              return response
              
            })
            .catch(error=>{
            this.$loading(false);
            console.log(error)
            this.$bvToast.toast('Gagal Diunggah', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
              return error
              })
        }
      },
    loaddata(){
        if (this.user.id_role === 'ptsp_prov' || this.user.id_role === 'ptsp_provinsi') {
            axios.get(this.url+"/tabel_nomine_provinsi?filter=idptsp,=,"+this.user.id_ptsp+";tahun,=,"+this.tahun, {
                headers: {
                    'xth': this.token
                }
            })
            .then(response => {
                this.pemaparan = response.data.data[0];
                this.pemaparan['fileppt'] = this.pemaparan['file_ppt_pemaparan']
                this.pemaparan['filelampiran'] = this.pemaparan['file_lampiran_pemaparan']
                console.log(this.pemaparan)
                if (this.pemaparan.length === 0) {
                    this.paparan = 'Tidak'
                } else {
                    this.paparan = 'Ya'
                }
                // this.idpenilaian=response.data.data.id;
            }).catch(error=>{
                // localStorage.setItem('idpenilaian', 'undefined')
                console.log(error)
                return error
                // this.loaddata()
                });
        } else if(this.user.id_role === 'ptsp_kab' || this.user.id_role === 'ptsp_kabkot' || this.user.id_role === 'ptsp_kabupaten'){
            axios.get(this.url+"/tabel_nomine_kabupaten?filter=idptsp,=,"+this.user.id_ptsp+";tahun,=,"+this.tahun, {
                headers: {
                    'xth': this.token
                }
            })
            .then(response => {
                this.pemaparan = response.data.data[0];
                this.pemaparan['fileppt'] = this.pemaparan['file_ppt_pemaparan']
                this.pemaparan['filelampiran'] = this.pemaparan['file_lampiran_pemaparan']
                console.log(this.pemaparan)
                if (this.pemaparan.length === 0) {
                    this.paparan = 'Tidak'
                } else {
                    this.paparan = 'Ya'
                }
                // this.idpenilaian=response.data.data.id;
            }).catch(error=>{
                // localStorage.setItem('idpenilaian', 'undefined')
                console.log(error)
                return error
                // this.loaddata()
                });
        } else if(this.user.id_role === 'ptsp_kot' || this.user.id_role === 'ptsp_kota'){
            axios.get(this.url+"/tabel_nomine_kota?filter=idptsp,=,"+this.user.id_ptsp+";tahun,=,"+this.tahun, {
                headers: {
                    'xth': this.token
                }
            })
            .then(response => {
                this.pemaparan = response.data.data[0];
                this.pemaparan['fileppt'] = this.pemaparan['file_ppt_pemaparan']
                this.pemaparan['filelampiran'] = this.pemaparan['file_lampiran_pemaparan']
                console.log(this.pemaparan)
                if (this.pemaparan.length === 0) {
                    this.paparan = 'Tidak'
                } else {
                    this.paparan = 'Ya'
                }
                // this.idpenilaian=response.data.data.id;
            }).catch(error=>{
                // localStorage.setItem('idpenilaian', 'undefined')
                console.log(error)
                return error
                // this.loaddata()
                });
        } else {
            axios.get(this.url+"/tabel_nomine_kl?filter=idkl,=,"+this.user.id_ptsp+";tahun,=,"+this.tahun, {
                headers: {
                    'xth': this.token
                }
            })
            .then(response => {
                this.pemaparan = response.data.data[0];
                this.pemaparan['fileppt'] = this.pemaparan['file_ppt_pemaparan']
                this.pemaparan['filelampiran'] = this.pemaparan['file_lampiran_pemaparan']
                console.log(this.pemaparan)
                if (this.pemaparan.length === 0) {
                    this.paparan = 'Tidak'
                } else {
                    this.paparan = 'Ya'
                }
                // this.idpenilaian=response.data.data.id;
            }).catch(error=>{
                // localStorage.setItem('idpenilaian', 'undefined')
                console.log(error)
                return error
                // this.loaddata()
                });
        }
        
    },
    urlba(){
        if (this.pemaparan.file_ba_pemaparan == null) {
            Swal.fire({
            title: "",
            text: "File BA Belum ada",
            icon: "info",
            confirmButtonClass: "btn btn-secondary"
            });
        }else{
            window.open(this.pemaparan.file_ba_pemaparan, '_blank')
        }
    },
    file_presentasi(){
        const fi = document.getElementById('fileppt');
        // Check if any file is selected.
        if (fi.files.length > 0) {
            for (const i = 0; i <= fi.files.length - 1; i++) {
 
                const fsize = fi.files.item(i).size;
                const file = Math.round((fsize / 1024));
                // The size of the file.
                if (file >= 30720) {
                    console.log(file)
                    alert(
                      "File terlalu besar, pilih file maksimal 30 mb");
                    // this.pm.l_perizinanoss_1 = null;
                    // this.pm.l_perizinanoss_1 = null;
                    this.loaddata()
                }
            }
        }
      },
      file_lampiran(){
        const fi = document.getElementById('filelampiran');
        // Check if any file is selected.
        if (fi.files.length > 0) {
            for (const i = 0; i <= fi.files.length - 1; i++) {
 
                const fsize = fi.files.item(i).size;
                const file = Math.round((fsize / 1024));
                // The size of the file.
                if (file >= 30720) {
                    console.log(file)
                    alert(
                      "File terlalu besar, pilih file maksimal 30 mb");
                    // this.pm.l_perizinanoss_1 = null;
                    // this.pm.l_perizinanoss_1 = null;
                    this.loaddata()
                }
            }
        }
      },
  },
  created(){
      
  }
//   destroyed() {
//     localStorage.removeItem("builderTab");
//   },
//   methods: {
//     /**
//      * Reset config
//      */
//     reset(event) {
//       event.preventDefault();
//       // remove existing saved config
//       localStorage.removeItem("config");
//       window.location.reload();
//     },

//     /**
//      * Set previous tab active
//      */
//     setActivePreviousTab() {
//       this.tabIndex = parseInt(localStorage.getItem("builderTab")) || 0;
//       const links = this.$refs["builder-tab"].querySelectorAll(".nav-link");
//       // remove active tab links
//       for (let i = 0; i < links.length; i++) {
//         links[i].classList.remove("active");
//       }
//       this.$refs["builder-tab"]
//         .querySelector(`[data-tab="${this.tabIndex}"]`)
//         .classList.add("active");
//     },
//     /**
//      * Set current active on click
//      * @param event
//      */
//     setActiveTab(event) {
//       const tab = event.target.closest('[role="tablist"]');
//       const links = tab.querySelectorAll(".nav-link");
//       // remove active tab links
//       for (let i = 0; i < links.length; i++) {
//         links[i].classList.remove("active");
//       }

//       // set clicked tab index to bootstrap tab
//       this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

//       // set current active tab
//       event.target.classList.add("active");

//       // keep active tab
//       localStorage.setItem("builderTab", this.tabIndex);
//     },

//     /**
//      * Submit form
//      * @param event
//      */
//     submit(event) {
//       event.preventDefault();
//       // save new config to localStorage
//       localStorage.setItem("config", JSON.stringify(this.config));
//       window.location.reload();
//     }
//   }
};
</script>